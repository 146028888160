import clsx from 'clsx'
import { colWidthCenterMap, ptMap, pbMap } from '@lib/token-maps'
import EventGive from '@components/event-give'
import type { TypeEventGiveBlockFields } from '@lib/generated-types'
import { generateBaseMediaQWidths } from '@lib/services/image-service'
import CaptionedMedia from '@cms/components/captioned-media'
import slugify from '@sindresorhus/slugify'
import { Container } from '@shc/ui'
import Analytics from '@components/analytics'

const EventGiveBlock = ({
  internalName,
  sectionLink,
  events,
  width,
  paddingTop,
  paddingBottom,
  ...props
}: TypeEventGiveBlockFields) => {
  return (
    <Container
      id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink as string)}
      className={clsx('grid grid-cols-12', ptMap[paddingTop], pbMap[paddingBottom])}
      {...props}>
      <div className={clsx(colWidthCenterMap[width], 'flex flex-row flex-wrap gap-8')}>
        {events.map(({ sys, fields }, index) => {
          return (
            <Analytics
              key={index}
              click={{
                name: 'component_click',
                data: {},
              }}
              contexts={[
                {
                  name: 'component',
                  data: {
                    component_text: fields.title,
                    component_url: fields.link,
                  },
                },
              ]}>
              <EventGive
                title={fields.title}
                image={
                  <CaptionedMedia
                    {...fields.image.fields}
                    aspectRatio="16:9"
                    className="rounded lg:rounded-none lg:rounded-l overflow-hidden"
                    imageClassName={clsx(
                      'w-full',
                      'rounded',
                      'md:min-w-[381px]',
                      'md:w-[381px]',
                      'lg:rounded-none',
                      'lg:rounded-l',
                      'scale-100',
                      'duration-200',
                      'group-hover:scale-105',
                      'group-hover:origin-center',
                      'group-hover:duration-200'
                    )}
                    extras={generateBaseMediaQWidths(4)}
                    showCaption={false}
                  />
                }
                link={fields.link}
                date={fields.date}
                time={fields.time}
                location={fields.location}
                className="group rounded w-full lg:drop-shadow-md lg:bg-gray-50"
              />
            </Analytics>
          )
        })}
      </div>
    </Container>
  )
}

export default EventGiveBlock
