import type * as cfTypes from '@lib/generated-types'
import type { Entry } from 'contentful'
import { type FC } from 'react'
// Import as lowercase to match the content type id.
import bioBlock from '@cms/blocks/bio-block'
import breadcrumbBlock from '@cms/blocks/breadcrumb-block'
import buttonBlock from '@cms/blocks/button-block'
import cardAccordionBlock from '@cms/blocks/card-accordion-block'
import cardIconBlock from '@cms/blocks/card-icon-block'
import cardImageBlock from '@cms/blocks/card-block'
import cardImageLongBlock from '@cms/blocks/card-image-long-block'
import cardStatBlock from '@cms/blocks/card-stat-block'
import contentBlock from '@cms/blocks/content-block'
import ctaBackgroundBlock from '@cms/blocks/cta-background-block'
import duplexImageBlock from '@cms/blocks/duplex-image-block'
import eventGiveBlock from '@cms/blocks/event-give-block'
import healthNewsStoriesBlock from '@cms/blocks/health-news-stories-block'
import heroBlock from '@cms/blocks/hero-block'
import navBarBlock from '@cms/blocks/nav-bar-block'
import placeDetailBlock from '@cms/blocks/place-detail-block'
import searchPromoBlock from '@cms/blocks/search-promo-block'
import tagsBlock from '@cms/blocks/tags-block'
import titleBackgroundBlock from '@cms/blocks/title-background-block'
import videoGalleryBlock from '@cms/blocks/video-gallery-block'
import fadPromoBlock from '@cms/blocks/fad-promo-block'
import BlockAnalyticsWrapper from '@components/block-analytics-wrapper'

// All block renderers get mapped here.
const rendererMap: Record<string, FC<any>> = {
  bioBlock,
  breadcrumbBlock,
  buttonBlock,
  cardAccordionBlock,
  cardIconBlock,
  cardImageBlock,
  cardImageLongBlock,
  cardStatBlock,
  contentBlock,
  ctaBackgroundBlock,
  duplexImageBlock,
  eventGiveBlock,
  healthNewsStoriesBlock,
  heroBlock,
  navBarBlock,
  placeDetailBlock,
  searchPromoBlock,
  tagsBlock,
  titleBackgroundBlock,
  videoGalleryBlock,
  fadPromoBlock,
}

export type Block = Entry<
  | cfTypes.TypeBioBlockFields
  | cfTypes.TypeBreadcrumbBlockFields
  | cfTypes.TypeButtonBlockFields
  | cfTypes.TypeCardAccordionBlockFields
  | cfTypes.TypeCardIconBlockFields
  | cfTypes.TypeCardImageBlockFields
  | cfTypes.TypeCardImageLongBlockFields
  | cfTypes.TypeCardStatBlockFields
  | cfTypes.TypeContentBlockFields
  | cfTypes.TypeCtaBackgroundBlockFields
  | cfTypes.TypeDuplexImageBlockFields
  | cfTypes.TypeEventGiveBlockFields
  | cfTypes.TypeHealthNewsStoriesBlockFields
  | cfTypes.TypeHeroBlockFields
  | cfTypes.TypeNavBarBlockFields
  | cfTypes.TypePlaceDetailBlockFields
  | cfTypes.TypeSearchPromoBlockFields
  | cfTypes.TypeTagsBlockFields
  | cfTypes.TypeTitleBackgroundBlockFields
  | cfTypes.TypeVideoGalleryBlockFields
  | cfTypes.TypeFadPromoBlockFields
>

interface BlockRendererProps {
  block: Block
}

export const BlockRenderer = (props: BlockRendererProps): JSX.Element => {
  const block: Block = props.block
  const contentType = block?.sys?.contentType?.sys?.id
  const BlockComponent = rendererMap[contentType]

  if (typeof BlockComponent === 'undefined') {
    return <div className="hidden">{contentType} is undefined in rendererMap</div>
  }

  return (
    <BlockAnalyticsWrapper
      value={{
        name: 'content',
        data: {
          content_type: block.sys.contentType.sys.id,
          content_internal_name: block.fields.internalName,
          content_entry_id: block.sys.id,
          content_level: 'block',
        },
      }}>
      <BlockComponent {...block.fields} />
    </BlockAnalyticsWrapper>
  )
}

export default BlockRenderer
