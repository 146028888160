import clsx from 'clsx'
import type { TypeButtonBlockFields } from '@lib/generated-types'
import Analytics from '@components/analytics'
import { routeMapper } from '@lib/mappers/menu-item-mapper'
import { ptMap, pbMap } from '@lib/token-maps'
import { Button, Container } from '@shc/ui'
import { mapButtonVariant } from '@lib/mappers/sharp-ui-mapper'

const ButtonBlock = ({
  internalName,
  button1Label,
  button1AnchorOrExternalLink,
  button1Link,
  button1Style,
  button2Label,
  button2AnchorOrExternalLink,
  button2Link,
  button2Style,
  paddingTop,
  paddingBottom,
  ...props
}: TypeButtonBlockFields) => {
  const link1 = routeMapper(button1Link, button1AnchorOrExternalLink)
  const link2 = routeMapper(button2Link, button2AnchorOrExternalLink)

  return (
    <Container
      className={clsx('grid grid-cols-12', ptMap[paddingTop], pbMap[paddingBottom])}
      {...props}>
      <div className="col-span-12 flex flex-col md:flex-row justify-center gap-5 w-full">
        {button1Label && (
          <Analytics
            click={{
              name: 'component_click',
              data: {},
            }}
            contexts={[
              {
                name: 'component',
                data: {
                  component_text: button1Label,
                  component_url: link1.route,
                },
              },
            ]}>
            <Button
              href={link1.route}
              target={link1.isInternal ? undefined : '_blank'}
              rel={link1.isInternal ? undefined : 'noopener noreferrer'}
              {...mapButtonVariant(button1Style)}>
              {button1Label}
            </Button>
          </Analytics>
        )}
        {button2Label && (
          <Analytics
            click={{
              name: 'component_click',
              data: {},
            }}
            contexts={[
              {
                name: 'component',
                data: {
                  component_text: button2Label,
                  component_url: link2.route,
                },
              },
            ]}>
            <Button
              href={link2.route}
              target={link2.isInternal ? undefined : '_blank'}
              rel={link2.isInternal ? undefined : 'noopener noreferrer'}
              {...mapButtonVariant(button2Style)}>
              {button2Label}
            </Button>
          </Analytics>
        )}
      </div>
    </Container>
  )
}

export default ButtonBlock
