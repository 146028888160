import clsx from 'clsx'
import { colWidthCenterMap, ptMap, pbMap, card2WidthMap, columnsMaxCardMap } from '@lib/token-maps'
import slugify from '@sindresorhus/slugify'
import type { TypeCardStatBlockFields } from '@lib/generated-types/TypeCardStatBlock'
import CardStat from '@components/card-stat'
import { Container } from '@shc/ui'

const CardStatBlock = ({
  internalName,
  sectionLink,
  cards,
  columnsMax,
  width,
  paddingTop,
  paddingBottom,
  cardAlignment = 'left',
  ...props
}: TypeCardStatBlockFields) => {
  return (
    <Container
      id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink as string)}
      className={clsx('grid grid-cols-12', ptMap[paddingTop], pbMap[paddingBottom])}
      {...props}>
      <div
        className={clsx(
          'flex flex-row flex-wrap gap-5',
          colWidthCenterMap[width],
          cardAlignment === 'center' ? 'justify-center' : 'justify-start'
        )}>
        {cards.map(({ fields }, index) => {
          return (
            <CardStat
              key={index}
              icon={fields.icon.fields.icon}
              prefix={fields.prefix}
              startingValue={fields.startingValue}
              finalValue={fields.finalValue}
              suffix={fields.suffix}
              summary={fields.summary}
              className={card2WidthMap[columnsMaxCardMap[columnsMax]]}
            />
          )
        })}
      </div>
    </Container>
  )
}

export default CardStatBlock
