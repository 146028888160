import clsx from 'clsx'
import {
  colWidthCenterMap,
  ptMap,
  pbMap,
  card2WidthMap,
  columnsMaxCardMap,
  colWidthLeftMap,
} from '@lib/token-maps'
import slugify from '@sindresorhus/slugify'
import { routeMapper } from '@lib/mappers/menu-item-mapper'
import type { TypeCardIconBlockFields } from '@lib/generated-types/TypeCardIconBlock'
import CardIcon from '@components/card-icon'
import ChatWrapper from '@components/chat-wrapper'
import { Container } from '@shc/ui'

const CardIconBlock = ({
  internalName,
  sectionLink,
  cards,
  columnsMax,
  width,
  blockAlignment,
  paddingTop,
  paddingBottom,
  cardAlignment = 'left',
  ...props
}: TypeCardIconBlockFields) => {
  return (
    <Container
      id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink)}
      className={clsx('grid grid-cols-12', ptMap[paddingTop], pbMap[paddingBottom])}
      {...props}>
      <div
        className={clsx(
          blockAlignment === 'center' && colWidthCenterMap[width],
          blockAlignment === 'left' && colWidthLeftMap[width],
          cardAlignment === 'center' ? 'justify-center' : 'justify-start',
          'flex flex-row flex-wrap gap-5'
        )}>
        {cards.map(({ fields, sys }) => {
          const { route, isInternal } = routeMapper(fields.link, fields.anchorOrExternalLink)
          const chat = fields.buttonModal === 'chat with us'
          return (
            <ChatWrapper key={sys.id} chat={chat}>
              <div
                className={card2WidthMap[columnsMaxCardMap[columnsMax]]}
                data-testid="chat wrapper div">
                <CardIcon
                  clickable={chat}
                  icon={fields.icon?.fields.icon}
                  title={fields.title}
                  summary={fields.summary}
                  linkLabel={fields.linkLabel}
                  link={route}
                  linkIsInternal={isInternal}
                />
              </div>
            </ChatWrapper>
          )
        })}
      </div>
    </Container>
  )
}

export default CardIconBlock
