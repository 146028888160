import clsx from 'clsx'
import type { TypeDuplexImageBlockFields } from '@lib/generated-types'
import { Button, Container } from '@shc/ui'
import Analytics from '@components/analytics'
import type { DuplexImageProps } from '@components/duplex-image'
import DuplexImage from '@components/duplex-image'
import { routeMapper } from '@lib/mappers/menu-item-mapper'
import { richTextRender } from '@lib/rich-text-base'
import { ptMap, pbMap, bgColorMap } from '@lib/token-maps'
import slugify from '@sindresorhus/slugify'
import CaptionedMedia from '@cms/components/captioned-media'
import { generateBaseMediaQWidths } from '@lib/services/image-service'

const DuplexImageBlock = ({
  internalName,
  sectionLink,
  variant,
  heading,
  headingStyle,
  description,
  image,
  imageRatio,
  button1Label,
  button1Link,
  button1AnchorOrExternalLink,
  button2Label,
  button2Link,
  button2AnchorOrExternalLink,
  imagePosition,
  stackOrder,
  backgroundColor,
  paddingTop,
  ...props
}: TypeDuplexImageBlockFields) => {
  const link1 = routeMapper(button1Link, button1AnchorOrExternalLink)
  const link2 = routeMapper(button2Link, button2AnchorOrExternalLink)

  const headingLevelMap: Record<
    TypeDuplexImageBlockFields['headingStyle'],
    DuplexImageProps['headingLevel']
  > = {
    H1: 1,
    H2: 2,
  }

  return (
    <div
      className={clsx(
        'grid grid-cols-12',
        pbMap['60px'],
        variant === 'hero' && ptMap[paddingTop],
        variant === 'standard' && !backgroundColor && ptMap[paddingTop],
        variant === 'standard' && backgroundColor && 'pt-12 lg:pt-15',
        variant === 'standard' && backgroundColor && bgColorMap[backgroundColor]
      )}
      {...props}>
      <Container
        className="col-span-12"
        id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink as string)}>
        <DuplexImage
          variant={variant}
          heading={heading}
          headingLevel={headingLevelMap[headingStyle]}
          description={richTextRender(description)}
          descriptionClassName="rich-text"
          image={
            <CaptionedMedia
              {...image.fields}
              extras={generateBaseMediaQWidths(6)}
              aspectRatio={imageRatio}
              imageClassName="rounded"
            />
          }
          imagePosition={imagePosition}
          stackOrder={stackOrder}>
          {button1Label && link1.route && (
            <Analytics
              click={{
                name: 'component_click',
                data: {},
              }}
              contexts={[
                {
                  name: 'component',
                  data: {
                    component_text: button1Label,
                    component_url: link1.route,
                  },
                },
              ]}>
              <Button
                href={link1.route}
                target={link1.isInternal ? undefined : '_blank'}
                rel={link1.isInternal ? undefined : 'noopener noreferrer'}
                width={button2Label && link2.route ? 'md' : 'lg'}>
                {button1Label}
              </Button>
            </Analytics>
          )}
          {button2Label && link2.route && (
            <Analytics
              click={{
                name: 'component_click',
                data: {},
              }}
              contexts={[
                {
                  name: 'component',
                  data: {
                    component_text: button2Label,
                    component_url: link2.route,
                  },
                },
              ]}>
              <Button
                href={link2.route}
                target={link2.isInternal ? undefined : '_blank'}
                rel={link2.isInternal ? undefined : 'noopener noreferrer'}
                width="md"
                variant="outlined">
                {button2Label}
              </Button>
            </Analytics>
          )}
        </DuplexImage>
      </Container>
    </div>
  )
}

export default DuplexImageBlock
