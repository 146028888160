import clsx from 'clsx'
import type { Document } from '@contentful/rich-text-types'
import omit from 'lodash/omit'

import Content from '@components/content'
import type { TypeContentBlockFields } from '@lib/generated-types'
import * as tokenMaps from '@lib/token-maps'
import { richTextRenderFactory } from '@lib/rich-text-options'
import { colWidthCenterMap, colWidthLeftMap } from '@lib/token-maps'
import slugify from '@sindresorhus/slugify'
import { Container } from '@shc/ui'

const ContentBlock = ({
  sectionLink,
  content,
  blockAlignment,
  textAlignment,
  width,
  listColumns,
  paddingTop,
  paddingBottom,
  ...props
}: TypeContentBlockFields) => {
  const richTextRenderChildren = richTextRenderFactory(width)

  return (
    <Container
      id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink)}
      as="section"
      className="grid grid-cols-12">
      <div
        className={clsx(
          blockAlignment === 'center' && colWidthCenterMap[width],
          blockAlignment === 'left' && colWidthLeftMap[width]
        )}>
        <Content
          textAlignment={textAlignment}
          listColumns={listColumns}
          className={clsx(
            'rich-text', // See utilities.css
            tokenMaps.ptMap[paddingTop],
            tokenMaps.pbMap[paddingBottom]
          )}
          {...omit(props, ['internalName'])}>
          {richTextRenderChildren(content as Document)}
        </Content>
      </div>
    </Container>
  )
}

export default ContentBlock
