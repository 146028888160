import clsx from 'clsx'
import type { TypePlaceDetailBlockFields } from '@lib/generated-types'
import { ptMap, pbMap, colWidthLeftMap, colWidthCenterMap } from '@lib/token-maps'
import LocationDetail from '@app/(main)/locations/_components/location-detail'
import slugify from '@sindresorhus/slugify'
import type {
  TypePlacePublic,
  TypeScheduleAmended,
  TypeScheduleException,
} from '@lib/location-types'
import { contentTypeBasePathMap } from '@lib/constants'
import { Container, Typography } from '@shc/ui'

export interface PlaceDetailBlockProps extends TypePlaceDetailBlockFields {
  location: TypePlacePublic & { map_url?: string }
  now?: string
}

const PlaceDetailBlock = ({
  internalName,
  sectionLink,
  slug,
  isLinked,
  blockAlignment,
  location,
  now = new Date().toISOString(),
  ...props
}: PlaceDetailBlockProps) => {
  if (!location) return <div className="hidden">Location not found for {slug}</div>
  return (
    <Container
      id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink as string)}
      className={clsx('grid grid-cols-12', ptMap['80px'], pbMap['80px'])}
      {...props}>
      <div
        className={clsx(
          blockAlignment === 'center' && colWidthCenterMap['8 columns'],
          blockAlignment === 'left' && colWidthLeftMap['8 columns']
        )}>
        {location.formatted_address && (
          <section id="how-to-find-us" className={pbMap['80px']}>
            <LocationDetail
              now={now}
              name={location.name}
              description={location.description}
              address={location.formatted_address}
              phone={location.phone_formatted}
              fax={location.fax_formatted}
              mapUrl={location.map_url}
              displayHours={true}
              schedule={location.schedules as TypeScheduleAmended[]}
              scheduleNotes={location.schedule_notes}
              scheduleExceptions={location.schedule_exceptions as TypeScheduleException[]}
              link={isLinked ? `${contentTypeBasePathMap['place']}${slug}` : undefined}
            />
          </section>
        )}
        {/* Parking information */}
        {location.parking_information && (
          <section id="parking">
            <Typography variant="h2" className="pb-6">
              Parking
            </Typography>
            <div>{location.parking_information}</div>
          </section>
        )}
      </div>
    </Container>
  )
}

export default PlaceDetailBlock
