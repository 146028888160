import type { ComponentPropsWithoutRef, ReactElement, ReactNode } from 'react'
import clsx from 'clsx'
import { bgColorMap } from '@lib/token-maps'

export interface TagsProps extends ComponentPropsWithoutRef<'div'> {
  heading?: string | ReactElement | ReactNode
  backgroundColor?: 'primary-50' | 'transparent'
}

const Tags = ({
  heading,
  backgroundColor = 'primary-50',
  className,
  children,
  ...props
}: TagsProps) => {
  return (
    <div className={clsx('w-full', bgColorMap[backgroundColor], className)} {...props}>
      <div
        className={clsx(
          'container mx-auto flex flex-col gap-4 justify-start',
          backgroundColor !== 'transparent' && 'py-8 lg:!px-6',
          backgroundColor === 'transparent' && 'lg:!px-0'
        )}>
        <div>{heading}</div>
        <div className="flex flex-row flex-wrap justify-start gap-2">{children}</div>
      </div>
    </div>
  )
}

export default Tags
