import type { GetServerSideProps } from 'next'
import GiveLayout from '@layouts/give-layout'
import type { BasePageProps } from '@lib/types'
import getMenus from '@lib/services/menu-service'
import {
  getPageBySlug,
  getBlocksWithData,
  getTagsFromEntry,
  getAlerts,
} from '@lib/services/page-service'
import type { TypeAlert, TypePageGive } from '@lib/generated-types'
import BlockRenderer from 'src/cms/renderers/block-renderer'
import Head from 'next/head'
import { buildCanonicalUrl, buildSlug } from '@lib/utilities/page-utilities'
import Analytics from '@components/analytics'
import PageAnalyticsWrapper from '@components/page-analytics-wrapper'

export const getServerSideProps: GetServerSideProps<PageProps> = async ({
  res,
  params,
  preview = false,
  query,
}) => {
  res.setHeader('Cache-Control', 'public, s-maxage=120, stale-while-revalidate=120')
  const contentType = 'pageGive'
  const slug = buildSlug(params?.slug)
  const canonicalUrl = buildCanonicalUrl(slug, contentType)
  const page = await getPageBySlug({ slug, preview, contentType })

  if (!page) {
    return {
      notFound: true,
    }
  }

  const site = 'Sharp.com/give'
  const [menuTypeMap, alerts, blocksWithData] = await Promise.all([
    getMenus({ site, preview }),
    getAlerts(),
    getBlocksWithData(
      page.fields.blocks,
      ['healthNewsStoriesBlock', 'placeDetailBlock'],
      slug,
      query
    ),
  ])
  // Update bolcks to be blocks with data
  for (const block of blocksWithData) {
    page.fields.blocks[block.index] = block.block
  }

  return {
    props: {
      preview,
      page,
      contentType,
      canonicalUrl,
      menuTypeMap,
      alerts,
      pageViewTrackingData: {
        page_type: contentType,
        language: 'en-US',
        page_entry_id: page?.sys?.id,
        tags: getTagsFromEntry(page),
      },
    },
  }
}

type PageProps = {
  page: TypePageGive
  contentType: string
  canonicalUrl: string
  alerts?: TypeAlert[]
} & BasePageProps

export default function Page({
  preview,
  menuTypeMap,
  page,
  contentType,
  canonicalUrl,
  alerts,
  pageViewTrackingData = {},
}: PageProps) {
  return (
    <GiveLayout
      alerts={alerts}
      preview={preview}
      header={{
        menu: menuTypeMap['Header'] ?? [],
        menuDrawer: menuTypeMap['Drawer'] ?? [],
        menuDrawerFeatured: menuTypeMap['Drawer featured'] ?? [],
        menuSearch: menuTypeMap['Search takeover'] ?? [],
        showMenuButton: 'mobile only',
        addMenuToMenuDrawer: true,
      }}
      footer={{
        menu: menuTypeMap['Footer'] ?? [],
        socialMenu: menuTypeMap['Footer social'] ?? [],
        copyrightDate: new Date(),
      }}
      title={page.fields.title}
      seoTitle={page.fields.seoTitle}
      seoDescription={page.fields.seoDescription}>
      <Head>
        <meta key="contentType" property="content-type" content={contentType} />
        <meta key="ogType" property="og:type" content="webpage" />
        <meta key="ogUrl" property="og:url" content={canonicalUrl} />
        <link rel="canonical" href={canonicalUrl} />
      </Head>

      <PageAnalyticsWrapper value={{ name: 'page', data: pageViewTrackingData }}>
        <Analytics view />

        {page.fields.blocks?.map((block) => (
          <BlockRenderer key={block.sys.id} block={block} />
        ))}
      </PageAnalyticsWrapper>
    </GiveLayout>
  )
}
