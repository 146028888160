import { Typography } from '@shc/ui'
import clsx from 'clsx'
import type { ComponentPropsWithoutRef, ReactElement } from 'react'
export interface BioProps extends ComponentPropsWithoutRef<'div'> {
  image?: ReactElement
  heading: string
  subHeading?: string
  body?: string
  link?: string
  // onClick?: any
}

const Bio = ({
  image,
  heading,
  subHeading,
  body,
  link,
  // onClick,
  className,
  ...props
}: BioProps) => {
  return (
    <div className={clsx('flex flex-row gap-5', className)} {...props}>
      {link ? (
        <>
          <a
            href={link}
            aria-label={`Link to ${heading}'s Bio`}
            className="outline-primary outline-offset-2 focus:outline-2">
            {image}
          </a>
          <a
            href={link}
            aria-label={`Link to ${heading}'s Bio`}
            className="outline-primary outline-offset-2 focus:outline-2">
            <div className="flex flex-col gap-3">
              <Typography variant="h3" className="group-hover:underline">
                {heading}
              </Typography>
              {subHeading && (
                <Typography as="div" variant="small-body-semibold">
                  {subHeading}
                </Typography>
              )}
              {body && <Typography variant="small-body">{body}</Typography>}
            </div>
          </a>
        </>
      ) : (
        <>
          {image}
          <div className="flex flex-col gap-3">
            <Typography variant="h3">{heading}</Typography>
            {subHeading && (
              <Typography as="div" variant="small-body-semibold">
                {subHeading}
              </Typography>
            )}
            {body && <Typography variant="small-body">{body}</Typography>}
          </div>
        </>
      )}
    </div>
  )
}

export default Bio
