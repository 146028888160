import clsx from 'clsx'

import { ptMap, pbMap } from '@lib/token-maps'
import type { TypeSearchPromoBlockFields } from '@lib/generated-types'
import { routeMapper } from '@lib/mappers/menu-item-mapper'
import { getIcon } from '@components/icons'
import { Container, Icon, Link, Typography } from '@shc/ui'
import ResponsiveImage from '@components/responsive-image'
import Analytics from '@components/analytics'

const SearchPromoBlock = ({
  internalName,
  variant,
  icon,
  image,
  title,
  summary,
  linkLabel,
  link,
  anchorOrExternalLink,
  paddingTop,
  paddingBottom,
  ...props
}: TypeSearchPromoBlockFields) => {
  const { route, isInternal } = routeMapper(link, anchorOrExternalLink)

  const card = (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row gap-2 items-center">
        {icon && (
          <Icon fixedWidth icon={getIcon(icon.fields.icon)} className="h-10 text-gray-700" />
        )}
        <div className="text-gray-700 font-semibold">{title}</div>
      </div>
      <div className="text-gray-700 font-medium">{summary}</div>
      {linkLabel && route && <div className="group-hover:underline font-bold">{linkLabel}</div>}
    </div>
  )

  // variant case
  if (variant === 'locations') {
    return (
      <Container className={clsx(ptMap[paddingTop], pbMap[paddingBottom])} {...props}>
        <div className="flex md:gap-5 lg:gap-15 items-center justify-between py-8 border-t">
          <div className="flex flex-col gap-3 justify-between">
            <Analytics
              click={{
                name: 'component_click',
                data: {},
              }}
              contexts={[
                {
                  name: 'component',
                  data: {
                    component_text: title,
                    component_url: route,
                  },
                },
              ]}>
              <Link
                noUnderline
                href={route}
                target={isInternal ? undefined : '_blank'}
                rel={isInternal ? undefined : 'noopener noreferrer'}
                className="font-medium group">
                <Typography
                  as={'h2'}
                  variant="h4"
                  className="text-primary-700 group-hover:underline font-bold">
                  {title}
                </Typography>
              </Link>
            </Analytics>
            <div className="text-gray-700 font-medium">{summary}</div>
          </div>
          {image && (
            <div className="flex flex-col justify-end items-center">
              {/* Link is present */}
              {image?.fields?.image && route && (
                <Analytics
                  click={{
                    name: 'component_click',
                    data: {},
                  }}
                  contexts={[
                    {
                      name: 'component',
                      data: {
                        component_text: `${title} [Image]`,
                        component_url: route,
                      },
                    },
                  ]}>
                  <Link href={route} className="group rounded overflow-hidden block">
                    <ResponsiveImage
                      image={{
                        altTag: image.fields.image.fields.title,
                        urlString: image.fields.image.fields?.file?.url,
                      }}
                      focalPoint="center"
                      columnWidthMax="100%"
                      className="md:w-[288px] md:h-[162px] lg:w-[335px] lg:h-[188px] rounded scale-100 duration-200 group-hover:scale-105 ease-in-out"
                    />
                  </Link>
                </Analytics>
              )}

              {/* Link is NOT present */}
              {image?.fields?.image && !route && (
                <ResponsiveImage
                  image={{
                    altTag: image.fields.image.fields.title,
                    urlString: image.fields.image.fields?.file?.url,
                  }}
                  focalPoint="center"
                  columnWidthMax="100%"
                  className="md:w-[288px] md:h-[162px] lg:w-[335px] lg:h-[188px] rounded"
                />
              )}
            </div>
          )}
        </div>
      </Container>
    )
  }

  // default case (non-variant)
  return (
    <Container className={clsx(ptMap[paddingTop], pbMap[paddingBottom])} {...props}>
      <div className="w-full md:w-fit md:max-w-[720px] md:min-w-[420px] px-5 py-4 cursor-pointer bg-gray-50 rounded drop-shadow-md h-full hover:bg-gray-75 hover:transition-color hover:duration-300 hover:ease-out hover:drop-shadow-darken-hover group">
        <Analytics
          click={{
            name: 'component_click',
            data: {},
          }}
          contexts={[
            {
              name: 'component',
              data: {
                component_text: linkLabel ?? '',
                component_url: route,
              },
            },
          ]}>
          <Link
            noUnderline
            href={route}
            target={isInternal ? undefined : '_blank'}
            rel={isInternal ? undefined : 'noopener noreferrer'}
            className="font-medium">
            {card}
          </Link>
        </Analytics>
      </div>
    </Container>
  )
}

export default SearchPromoBlock
