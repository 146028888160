import Hero from '@components/hero'
import type { TypeHeroBlockFields } from '@lib/generated-types'
import { richTextRender } from '@lib/rich-text-base'
import type { Json } from '@lib/types'
import omit from 'lodash/omit'
import clsx from 'clsx'
import type { ContentfulImagesAPI } from '@components/contentful-image'
import { heroHeightClassMap, heroHeightImgMap } from '@lib/token-maps'
import { routeMapper } from '@lib/mappers/menu-item-mapper'
import ResponsiveImage from '@components/responsive-image'
import { mapButtonVariant } from '@lib/mappers/sharp-ui-mapper'

const HeroBlock = ({
  height,
  heightScaler,
  sectionLink,
  image,
  backgroundColor,
  textColor,
  overlay,
  preheading,
  heading,
  description,
  button1Style,
  button1Link,
  button1AnchorOrExternalLink,
  button1Label,
  button2Style,
  button2Link,
  button2AnchorOrExternalLink,
  button2Label,
  ...props
}: TypeHeroBlockFields) => {
  const link1 = routeMapper(button1Link, button1AnchorOrExternalLink)
  const link2 = routeMapper(button2Link, button2AnchorOrExternalLink)

  return (
    <Hero
      {...omit(props, ['internalName'])}
      sectionLink={sectionLink}
      image={
        image?.fields.image && (
          <ResponsiveImage
            heightMap={heroHeightImgMap[height]}
            heightScaler={heightScaler}
            image={image?.fields.image}
            focalPoint={image.fields.focalPoint.toLowerCase() as ContentfulImagesAPI['f']}
            className={clsx(heroHeightClassMap[height])}
            imageClassName={clsx(heroHeightClassMap[height])}
          />
        )
      }
      backgroundColor={backgroundColor}
      textColor={textColor}
      overlay={overlay}
      preheading={preheading}
      heading={heading}
      description={richTextRender(description)}
      button1={{
        ...mapButtonVariant(button1Style),
        href: link1.route,
        children: button1Label,
      }}
      button2={{
        ...mapButtonVariant(button2Style),
        href: link2.route,
        children: button2Label,
      }}
    />
  )
}

export default HeroBlock
