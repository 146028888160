import clsx from 'clsx'
import Link from 'next/link'
import type { TypeTagsBlockFields } from '@lib/generated-types'
import { ptAtLgMap, pbAtLgMap, colWidthCenterMap } from '@lib/token-maps'
import Tags from '@components/tags'
import { contentTypeBasePathMap } from '@lib/constants'
import slugify from '@sindresorhus/slugify'
import { Button, Container } from '@shc/ui'
import Analytics from '@components/analytics'

const TagsBlock = ({
  title,
  tags,
  sectionLink,
  width,
  backgroundColor,
  paddingTop,
  paddingBottom,
  ...props
}: TypeTagsBlockFields) => {
  return (
    <Container
      id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink as string)}
      fullWidth="lg"
      className={clsx('grid grid-cols-12', ptAtLgMap[paddingTop], pbAtLgMap[paddingBottom])}
      {...props}>
      <Tags
        heading={<p className="text-sm text-left font-semibold">{title}</p>}
        backgroundColor={backgroundColor}
        className={clsx(colWidthCenterMap[width])}>
        {tags.map(({ sys, fields }, idx) => {
          const href = `${contentTypeBasePathMap[sys.contentType.sys.id]}${fields.slug}`

          return (
            <Analytics
              key={idx}
              click={{
                name: 'component_click',
                data: {},
              }}
              contexts={[
                {
                  name: 'component',
                  data: {
                    component_text: fields.title,
                    component_url: href,
                  },
                },
              ]}>
              <Link href={href} passHref>
                <Button variant="outlined" size="xs" width="auto">
                  {fields.title}
                </Button>
              </Link>
            </Analytics>
          )
        })}
      </Tags>
    </Container>
  )
}

export default TagsBlock
