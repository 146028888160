import type { TitleBackgroundProps } from '@components/title-background'
import TitleBackground from '@components/title-background'
import type { TypeTitleBackgroundBlockFields } from '@lib/generated-types'
import { richTextRender } from '@lib/rich-text-base'
import slugify from '@sindresorhus/slugify'

const TitleBackgroundBlock = (props: TypeTitleBackgroundBlockFields) => {
  const {
    internalName,
    sectionLink,
    title,
    titleHeading,
    description,
    textAlignment,
    backgroundColor,
    backgroundImage,
    blockAlignment,
    overlap,
    ...rest
  } = props

  const headingMap: Record<
    TypeTitleBackgroundBlockFields['titleHeading'],
    TitleBackgroundProps['headingLevel']
  > = {
    H1: 1,
    H2: 2,
    H3: 3,
  }

  return (
    <TitleBackground
      id={typeof sectionLink !== 'undefined' ? slugify(sectionLink) : undefined}
      heading={title}
      headingLevel={headingMap[titleHeading]}
      description={richTextRender(description)}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      textAlignment={textAlignment}
      blockAlignment={blockAlignment}
      overlap={overlap}
      {...rest}
    />
  )
}

export default TitleBackgroundBlock
