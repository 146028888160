import clsx from 'clsx'
import { colWidthCenterMap, colWidthLeftMap, ptMap, pbMap } from '@lib/token-maps'
import Bio from '@components/bio'
import type { TypeBioBlockFields } from '@lib/generated-types'
import { generateBaseMediaQWidths } from '@lib/services/image-service'
import CaptionedMedia from '@cms/components/captioned-media'
import slugify from '@sindresorhus/slugify'
import { contentTypeBasePathMap } from '@lib/constants'
import Analytics from '@components/analytics'
import { Container } from '@shc/ui'

type TypeBioBlockProps = {
  subHeading?: string
} & TypeBioBlockFields

const BioBlock = ({
  internalName,
  sectionLink,
  persons,
  width,
  blockAlignment,
  paddingTop,
  paddingBottom,
  subHeading,
  ...props
}: TypeBioBlockProps) => {
  return (
    <Container
      id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink as string)}
      className={clsx('grid grid-cols-12', ptMap[paddingTop], pbMap[paddingBottom])}
      {...props}>
      <div
        className={clsx(
          blockAlignment === 'center' && colWidthCenterMap[width],
          blockAlignment === 'left' && colWidthLeftMap[width],
          'flex flex-col gap-15'
        )}>
        {persons.map(({ fields, sys }, index) => {
          let linkUrl: string | undefined
          if (fields.profileUrl) {
            linkUrl = fields.profileUrl
          } else if (fields.roles.includes('Author')) {
            linkUrl = contentTypeBasePathMap['person'] + fields.slug
          } else {
            linkUrl = undefined
          }

          return (
            <Analytics
              key={sys.id}
              click={{
                name: 'component_click',
                data: {},
              }}
              contexts={[
                {
                  name: 'component',
                  data: {
                    component_text: fields.name,
                    component_url: linkUrl,
                  },
                },
                {
                  name: 'person',
                  data: {
                    person_entry_id: sys.id,
                    person_name: fields.name,
                  },
                },
              ]}>
              <Bio
                image={
                  <CaptionedMedia
                    internalName=""
                    className="rounded-full overflow-hidden flex-shrink-0"
                    focalPoint="Face"
                    image={fields.image}
                    extras={generateBaseMediaQWidths(4)}
                    imageClassName={clsx(
                      'rounded-full',
                      'min-w-[48px]',
                      'h-12',
                      'w-12',
                      'md:h-25',
                      'md:w-25',
                      'md:min-w-[100px]'
                    )}
                    aspectRatio="1:1"
                    showCaption={false}
                  />
                }
                heading={fields.jobTitle ? `${fields.name}, ${fields.jobTitle}` : fields.name}
                subHeading={subHeading ? subHeading : fields.jobTitle || fields.roles[0]}
                body={fields.summary}
                link={linkUrl}
                className="group"
              />
            </Analytics>
          )
        })}
      </div>
    </Container>
  )
}

export default BioBlock
