import clsx from 'clsx'
import { colWidthCenterMap, ptMap, pbMap } from '@lib/token-maps'
import CardLong from '@components/card-long'
import type {
  TypeCardImageLongBlockFields,
  TypeCardImageLongItemFields,
  TypeNewsArticleFields,
} from '@lib/generated-types'
import { generateBaseMediaQWidths } from '@lib/services/image-service'
import CaptionedMedia from '@cms/components/captioned-media'
import { contentTypeBasePathMap } from '@lib/constants'
import slugify from '@sindresorhus/slugify'
import { routeMapper } from '@lib/mappers/menu-item-mapper'
import Analytics from '@components/analytics'
import { Container } from '@shc/ui'

const CardImageLongBlock = ({
  internalName,
  sectionLink,
  cards,
  width,
  paddingTop,
  paddingBottom,
  ...props
}: TypeCardImageLongBlockFields) => {
  return (
    <Container
      id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink)}
      className={clsx('grid grid-cols-12', ptMap[paddingTop], pbMap[paddingBottom])}
      {...props}>
      <div className={clsx(colWidthCenterMap[width], 'flex flex-row flex-wrap gap-8')}>
        {cards.map(({ sys, fields }, index) => {
          const contentType = sys.contentType.sys.id
          let preHeading: string | undefined, link: string, linkIsInternal: boolean
          if (contentType === 'cardImageLongItem') {
            const longFields = fields as TypeCardImageLongItemFields
            const { route, isInternal } = routeMapper(
              longFields.link,
              longFields.anchorOrExternalLink
            )
            link = route
            linkIsInternal = isInternal ? isInternal : false
            preHeading = longFields.preHeading
          } else {
            const newsFields = fields as TypeNewsArticleFields
            link = contentTypeBasePathMap[contentType] + newsFields.slug
            linkIsInternal = true
            preHeading = undefined
          }
          return (
            <Analytics
              key={index}
              click={{
                name: 'component_click',
                data: {},
              }}
              contexts={[
                {
                  name: 'component',
                  data: {
                    component_text: fields.title,
                    component_url: link,
                  },
                },
                {
                  name: 'content',
                  data: {
                    content_type: contentType,
                    content_entry_id: sys.id,
                    content_level: 'item',
                    content_internal_name: internalName,
                  },
                },
              ]}>
              <CardLong
                preHeading={preHeading}
                title={fields.title}
                summary={fields.summary}
                image={
                  <CaptionedMedia
                    {...fields.image.fields}
                    aspectRatio="4:3"
                    className="rounded md:rounded-none md:rounded-l overflow-hidden"
                    imageClassName={clsx(
                      'w-full',
                      'rounded',
                      'md:min-w-[286px]',
                      'md:w-[286px]',
                      'md:rounded-none',
                      'md:rounded-l',
                      'scale-100',
                      'duration-200',
                      link && 'group-hover:scale-105',
                      link && 'group-hover:origin-center',
                      link && 'group-hover:duration-200'
                    )}
                    extras={generateBaseMediaQWidths(4)}
                    showCaption={false}
                  />
                }
                link={link}
                linkIsInternal={linkIsInternal}
                className="group rounded w-full md:drop-shadow-md md:bg-gray-50"
              />
            </Analytics>
          )
        })}
      </div>
    </Container>
  )
}

export default CardImageLongBlock
