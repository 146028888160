import { Icon, Typography } from '@shc/ui'
import type { ComponentPropsWithoutRef, ReactElement } from 'react'
import { getIcon } from './icons'
export interface EventGiveProps extends ComponentPropsWithoutRef<'div'> {
  title: string
  image: ReactElement
  link: string
  date: string
  time?: string
  location?: string
  onClick?: any
}

const EventGive = ({
  title,
  image,
  link,
  date,
  time,
  location,
  onClick,
  className,
  ...props
}: EventGiveProps) => {
  return (
    <div className={className} {...props}>
      <a
        href={link}
        onClick={onClick}
        target="_blank"
        rel="noopener noreferrer"
        className="outline-primary outline-offset-2 focus:outline-2">
        <article className="flex flex-col gap-4 md:flex-row md:gap-6 lg:gap-8 items-start md:items-center">
          <div className="w-full md:w-auto">{image}</div>
          <div className="flex flex-col gap-2 justify-center lg:pr-8 lg:py-5">
            <Typography variant="h5" className="text-left group-hover:underline">
              {title}
            </Typography>
            <div className="flex flex-col lg:flex-row gap-2 lg:gap-8 xl:gap-15 lg:items-center">
              <div className="flex flex-row gap-2 items-center">
                <Icon fixedWidth icon="calendar-days" className="h-4 text-primary-700/25 text-sm" />
                <div className="text-sm font-medium">{date}</div>
              </div>
              {time && (
                <div className="flex flex-row gap-2 items-center">
                  <Icon fixedWidth icon="clock" className="h-4 text-primary-700/25 text-sm" />
                  <div className="text-sm font-medium">{time}</div>
                </div>
              )}
            </div>
            {location && (
              <div className="flex flex-row gap-2 items-start">
                <Icon
                  fixedWidth
                  icon="location-dot"
                  className="h-4 text-primary-700/25 text-sm mt-[6px]"
                />
                <div className="text-sm font-medium whitespace-pre-wrap">{location}</div>
              </div>
            )}
          </div>
        </article>
      </a>
    </div>
  )
}

export default EventGive
