import React, { type ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'
import type { TypeVideoGalleryBlockFields } from '@lib/generated-types'
import MediaVideoCarousel from '@components/media-video-carousel'
import { colWidthCenterMap, colWidthLeftMap, pbMap, ptMap } from '@lib/token-maps'
import { richTextRender } from '@lib/rich-text-base'
import { Container } from '@shc/ui'

interface VideoGalleryProps
  extends ComponentPropsWithoutRef<'section'>,
    TypeVideoGalleryBlockFields {}

const VideoGalleryBlock = ({
  internalName,
  sectionLink,
  media,
  blockAlignment,
  paddingTop,
  paddingBottom,
  className,
}: VideoGalleryProps) => {
  const mediaFormatted = media.map((video) => {
    const { videoUrl, title, caption, fullScreen, autoPlay, loop } = video.fields
    return {
      videoUrl,
      title,
      fullScreen,
      autoPlay,
      loop,
      children: richTextRender(caption),
    }
  })
  return (
    <div className={clsx(ptMap[paddingTop], pbMap[paddingBottom])}>
      <Container as="section" id={sectionLink} className={clsx('grid grid-cols-12', className)}>
        <div
          className={clsx(
            blockAlignment === 'center' && colWidthCenterMap['8 columns'],
            blockAlignment === 'left' && colWidthLeftMap['8 columns']
          )}>
          <MediaVideoCarousel media={mediaFormatted} showThumbs={true} />
        </div>
      </Container>
    </div>
  )
}

export default VideoGalleryBlock
