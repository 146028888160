import clsx from 'clsx'
import { colWidthCenterMap, ptMap, pbMap, cardWidthMap, columnsMaxCardMap } from '@lib/token-maps'
import Card from '@components/card'
import Analytics from '@components/analytics'
import type {
  TypeCardImageBlockFields,
  TypeCardImageItemFields,
  TypeGiveNewsFields,
  TypeNewsArticleFields,
  TypePlaceFields,
} from '@lib/generated-types'
import { generateBaseMediaQWidths } from '@lib/services/image-service'
import CaptionedMedia from '@cms/components/captioned-media'
import { contentTypeBasePathMap } from '@lib/constants'
import slugify from '@sindresorhus/slugify'
import { routeMapper } from '@lib/mappers/menu-item-mapper'
import { Container } from '@shc/ui'

const CardBlock = ({
  internalName,
  sectionLink,
  cards,
  width,
  columnsMax,
  paddingTop,
  paddingBottom,
  cardAlignment = 'left',
  ...props
}: TypeCardImageBlockFields) => {
  return (
    <Container
      id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink as string)}
      className={clsx('grid grid-cols-12', ptMap[paddingTop], pbMap[paddingBottom])}
      {...props}>
      <div
        className={clsx(
          'flex flex-row flex-wrap gap-x-5 gap-y-8 lg:gap-y-12',
          colWidthCenterMap[width],
          cardAlignment === 'center' ? 'justify-center' : 'justify-start'
        )}>
        {cards.map(({ fields, sys }, index) => {
          const contentType = sys.contentType.sys.id
          let link: string, linkIsInternal: boolean
          if (contentType === 'cardImageItem') {
            const cardFields = fields as TypeCardImageItemFields
            const { route, isInternal } = routeMapper(
              cardFields.link,
              cardFields.anchorOrExternalLink
            )
            link = route
            linkIsInternal = isInternal ? isInternal : false
          } else {
            const newsFields = fields as
              | TypeNewsArticleFields
              | TypeGiveNewsFields
              | TypePlaceFields
            link = contentTypeBasePathMap[contentType] + newsFields.slug
            linkIsInternal = true
          }

          if (fields.image)
            return (
              <Analytics
                key={index}
                click={{
                  name: 'component_click',
                  data: {},
                }}
                contexts={[
                  {
                    name: 'component',
                    data: {
                      component_text: fields.title,
                      component_url: link,
                    },
                  },
                ]}>
                <Card
                  title={fields.title}
                  summary={fields.summary}
                  image={
                    <CaptionedMedia
                      {...fields.image.fields}
                      className="rounded overflow-hidden"
                      imageClassName={clsx(
                        'rounded',
                        'scale-100',
                        'duration-200',
                        link && 'group-hover:scale-105',
                        link && 'group-hover:origin-center',
                        link && 'group-hover:duration-200'
                      )}
                      extras={generateBaseMediaQWidths(4)}
                      showCaption={false}
                    />
                  }
                  link={link}
                  linkIsInternal={linkIsInternal}
                  className={clsx('group', cardWidthMap[columnsMaxCardMap[columnsMax]])}
                />
              </Analytics>
            )
        })}
      </div>
    </Container>
  )
}

export default CardBlock
