/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-expressions */
import type { ComponentPropsWithoutRef, ReactNode } from 'react'
import clsx from 'clsx'
import { backgroundColorMap } from '@lib/token-maps'
import { routeMapper } from '@lib/mappers/menu-item-mapper'
import Content from './content'
import Analytics from '@components/analytics'
import { Button, Container, Typography } from '@shc/ui'
import type { ButtonProps } from '@shc/ui/dist/components/Button/Button'

export interface HeroProps extends ComponentPropsWithoutRef<'div'> {
  sectionLink?: string
  textColor?: 'gray-700' | 'white'
  image?: JSX.Element
  backgroundColor?: 'gray-50' | 'primary' | 'primary-50' | 'secondary' | 'secondary-50'
  overlay?: '50%' | '60%' | '75%'
  preheading?: string
  heading?: string
  description?: string | ReactNode
  button1?: ButtonProps
  button2?: ButtonProps
}

const Hero = ({
  sectionLink,
  image,
  backgroundColor,
  textColor,
  overlay,
  preheading,
  heading,
  description,
  button1,
  button2,
  ...props
}: HeroProps) => {
  const backgroundClassNames = backgroundColor && backgroundColorMap[backgroundColor]
  const overlayClassName = overlay && {
    ...(overlay
      ? {
          background: `linear-gradient(90deg, rgba(0, 0, 0, ${overlay}) 0%, rgba(0, 0, 0, 0) 100%)`,
        }
      : {}),
  }

  const link1 = routeMapper(undefined, button1?.href)
  const link2 = routeMapper(undefined, button2?.href)

  const textColorClassName = clsx(
    textColor === 'gray-700' && 'text-gray-700',
    textColor === 'white' && 'text-white'
  )
  const descriptionTextColorClassName = clsx(
    textColor === 'gray-700' && 'prose prose-p:text-gray-700 prose-strong:text-gray-700',
    textColor === 'white' && 'prose prose-p:text-white prose-strong:text-white'
  )

  return (
    <>
      <section id={sectionLink}>
        <div className={clsx('relative', backgroundClassNames)}>
          <div className="empty:hidden">{image}</div>
          {/* overlay only for desktop (if an overlay value is chosen)  */}
          {overlay && (
            <div
              className="lg:absolute lg:top-0 lg:left-0 lg:right-0 lg:bottom-0"
              style={overlayClassName}></div>
          )}
          {/* desktop content */}
          {heading && (
            <Container
              as="section"
              className="hidden lg:block lg:absolute lg:top-0 lg:left-0 lg:right-0 lg:bottom-0">
              <div className="flex flex-col content-center justify-center h-full">
                {preheading && (
                  <p className={clsx('w-5/12 mb-5 font-semibold text-sm', textColorClassName)}>
                    {preheading}
                  </p>
                )}
                <Typography
                  variant="h1"
                  className={clsx('w-1/2 xl:w-5/12', description && 'mb-5', textColorClassName)}>
                  {heading}
                </Typography>
                <div className={clsx('w-1/2 xl:w-5/12 empty:hidden', textColorClassName)}>
                  <Content
                    textAlignment="left"
                    className={clsx('font-medium', descriptionTextColorClassName)}>
                    {description}
                  </Content>
                </div>
                <div className={clsx('flex flex-row gap-5 mt-10 w-1/2 xl:w-5/12 empty:hidden')}>
                  {button1?.variant && button1?.href && button1?.children && (
                    <Analytics
                      click={{
                        name: 'component_click',
                        data: {},
                      }}
                      contexts={[
                        {
                          name: 'component',
                          data: {
                            component_text: button1.children as string,
                            component_url: button1.href,
                          },
                        },
                      ]}>
                      <Button
                        variant={button1.variant}
                        color={button1.color}
                        className={button1.className}
                        width={button2?.variant && button2?.href && button2?.children ? 'md' : 'lg'}
                        aria-label=""
                        href={button1.href}
                        target={link1.isInternal ? undefined : '_blank'}
                        rel={link1.isInternal ? undefined : 'noopener noreferrer'}>
                        {button1.children}
                      </Button>
                    </Analytics>
                  )}
                  {button2?.variant && button2?.href && button2?.children && (
                    <Analytics
                      click={{
                        name: 'component_click',
                        data: {},
                      }}
                      contexts={[
                        {
                          name: 'component',
                          data: {
                            component_text: button2.children as string,
                            component_url: button2.href,
                          },
                        },
                      ]}>
                      <Button
                        variant={button2.variant}
                        color={button2.color}
                        className={button2.className}
                        width="md"
                        aria-label=""
                        href={button2.href}
                        target={link2.isInternal ? undefined : '_blank'}
                        rel={link2.isInternal ? undefined : 'noopener noreferrer'}>
                        {button2.children}
                      </Button>
                    </Analytics>
                  )}
                </div>
              </div>
            </Container>
          )}
        </div>
      </section>

      {heading && (
        <div className="bg-primary-50">
          <Container as="section">
            <div className="flex flex-col py-5 md:py-6 lg:hidden">
              {preheading && (
                <p className="mb-3 font-semibold text-sm text-gray-700">{preheading}</p>
              )}
              <Typography variant="h1" className={clsx('text-left', description && 'mb-3')}>
                {heading}
              </Typography>

              <div className="font-medium prose prose-p:mt-5 prose-p:md:mt-6 prose-p:!text-gray-700 prose-strong:!text-gray-700 empty:hidden">
                <Content
                  textAlignment="left"
                  className={clsx('font-medium', descriptionTextColorClassName)}>
                  {description}
                </Content>
              </div>

              <div className={clsx('flex flex-col sm:flex-row gap-5 mt-5 w-full sm:w-3/4')}>
                {/* sm:w-3/4 is my attempt at narrowing the button container to make the 2-button flex yield reasonable button widths */}
                {button1?.variant && button1?.href && button1.children && (
                  <Button
                    width={button2?.variant && button2?.href && button2.children ? 'md' : 'lg'}
                    aria-label=""
                    href={button1.href}
                    target={link1.isInternal ? undefined : '_blank'}
                    rel={link1.isInternal ? undefined : 'noopener noreferrer'}>
                    {button1.children}
                  </Button>
                )}
                {button2?.variant && button2?.href && button2.children && (
                  <Button
                    variant="outlined"
                    width="md"
                    aria-label=""
                    href={button2.href}
                    target={link2.isInternal ? undefined : '_blank'}
                    rel={link2.isInternal ? undefined : 'noopener noreferrer'}>
                    {button2.children}
                  </Button>
                )}
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  )
}

export default Hero
