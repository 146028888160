import type { ReactElement, ReactNode, ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'
import { bgColorMap, bgImageMap, colWidthCenterMap, colWidthLeftMap } from '@lib/token-maps'
import { Container, Typography } from '@shc/ui'

export interface TitleBackgroundProps extends ComponentPropsWithoutRef<'section'> {
  heading: string
  headingLevel: 1 | 2 | 3
  description?: string | ReactElement | ReactNode
  backgroundColor: 'gray-50' | 'primary' | 'primary-50' | 'secondary' | 'secondary-50'
  backgroundImage?: keyof typeof bgImageMap
  textAlignment?: 'center' | 'left'
  blockAlignment: 'center' | 'left'
  overlap: 'false' | 'true'
}

const TitleBackground = ({
  heading,
  headingLevel,
  description,
  backgroundColor,
  backgroundImage,
  className,
  textAlignment,
  blockAlignment,
  overlap,
  ...props
}: TitleBackgroundProps) => {
  const contentContainer = clsx(
    textAlignment === 'center' && `text-center`,
    textAlignment === 'left' && `text-left`
  )

  const blockContainer = clsx(
    blockAlignment === 'center' && `${colWidthCenterMap['8 columns']}`,
    blockAlignment === 'left' && `${colWidthLeftMap['8 columns']}`
  )

  const sectionContainer = clsx(
    overlap === 'false' ? 'pb-8 md:pb-12' : '-mb-12 lg:-mb-15 pb-20 lg:pb-[108px]',
    bgColorMap[backgroundColor],
    backgroundImage && bgImageMap[backgroundImage],
    className,
    'bg-cover bg-center'
  )

  return (
    <section {...props} className={sectionContainer}>
      <Container className="grid grid-cols-12">
        <div className={blockContainer}>
          <div className={contentContainer}>
            <Typography variant={`h${headingLevel}`} className="pt-8 md:pt-12">
              {heading}
            </Typography>
            {description && <div className="pt-4">{description}</div>}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default TitleBackground
